import {Alert, AlertColor, Box, Button, Container, SelectChangeEvent, Typography} from "@mui/material";
import React, {MouseEventHandler, useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import {
    GridColDef,
    GridColumnVisibilityModel,
    GridEventListener,
    GridFilterModel,
    GridRenderCellParams,
    GridRowModes,
    GridRowModesModel,
    GridRowParams, GridSortModel, gridVisibleColumnFieldsSelector,
    MuiEvent, useGridApiRef
} from "@mui/x-data-grid";
import {Banner} from "../../banner/banner.component";
import {columnSearchCacheGet, columnSearchCacheSet, searchCacheGet, searchCacheSet} from "../../dataGrid/searchCache";
import {postEditedCellData, postNewCellData, useAssetGroupDetails, useAssetGroupDetailsCount} from "../assetGroups.hooks";
import {CustomDataGrid} from "../../dataGrid/dataGrid.component";
// import {IsAdmin} from "../../auth/rolesAuth";
import {AssetGroupDetails} from "../assetGroups.types";
import {useAuth} from "../../auth/auth.hooks";


interface gridProps {
    searchCacheKey: string;
    groupId: number;
    groupName: string;
    auth: {accessToken: string, config: Record<string, unknown>}
}

interface TabButtonProps {
    name: string;
    thisTab: number;
    currentTab: number;
    onButtonClick: MouseEventHandler<HTMLButtonElement>;
}

interface GroupDetailTabProps {
    currentTab: number;
    setTab: React.Dispatch<React.SetStateAction<number>>;
}

interface ShowTabProps {
    currentTab: number;
    groupId: number;
    groupName: string;
}

interface ShowAlertProps {
    type: AlertColor | undefined;
    message: string;
}

enum TabType {
    NA = -1,
    Assets = 0,
    Info = 1,
    Members = 2,
}

function ShowAlert({type, message}: ShowAlertProps): JSX.Element | null {
    if(type === undefined) {
        return null;
    }

    return <Alert style={{marginTop: 7, marginBottom: 7}} severity={type}>{message}</Alert>
}

const initialVisibilityModel = {
    object_id: false,
};

export function AssetGroupDetailsGrid({searchCacheKey, groupId, groupName, auth}: gridProps): JSX.Element {
    const [paginationModel, setPaginationModel] = useState({page: 0, pageSize: 10});
    const [search, setSearch] = useState<any[] | undefined>(searchCacheGet(searchCacheKey));
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [alert, setAlert] = useState<{type: AlertColor | undefined, message: string}>({type: undefined, message: ""});
    const [visibilityModel, setVisibilityModel] = useState<any>(initialVisibilityModel);
    const [sortModel, setSortModel] = useState<GridSortModel | undefined>(undefined);
    const [columnSearch, setColumnSearch] = useState(columnSearchCacheGet(searchCacheKey));
    const [columnSearchParams, setColumnSearchParams] = useState(columnSearchCacheGet(searchCacheKey));

    const filter = useRef<any[] | undefined>(undefined);
    const AssetGroupDetailsInfo = useAssetGroupDetails(paginationModel.pageSize, paginationModel.page, groupId, search, sortModel, columnSearchParams);
    const rowCount = useAssetGroupDetailsCount(groupId, search, columnSearchParams);
    const limitExceeded = AssetGroupDetailsInfo.data !== undefined && AssetGroupDetailsInfo.data.length > 100;
    const apiRef = useGridApiRef();

    const [rows, setRows] = useState<AssetGroupDetails[] | undefined>(undefined);
    const [totalRows, setTotalRows] = useState<number>(0);

    useEffect(() => {
        searchCacheSet(searchCacheKey, search);
        columnSearchCacheSet(searchCacheKey, columnSearch);
    })

    useEffect(() => {
        setRows(AssetGroupDetailsInfo.data);
    }, [AssetGroupDetailsInfo.data])

    useEffect(() => {
        setTotalRows(rowCount.data !== undefined ? rowCount.data : 0);
    }, [rowCount.data])

    useEffect(() => {
        const timer = setTimeout(() => setColumnSearchParams(columnSearch), 500);

        return () => {
            clearTimeout(timer);
        };
    }, [columnSearch])

    useEffect(() => {
        setPaginationModel({page: 0, pageSize: paginationModel.pageSize})
    },[columnSearchParams])

    const navigate = useNavigate();

    const columnConfig: GridColDef[] = [
        {field: "object_id", headerName: "ID", minWidth: 50, editable: false, hideable: false, sortable: false},
        {field: "product_name", headerName: "Product Name", minWidth: 140, flex: 1, editable: true, sortable: false},
        {field: "description", headerName: "Description", minWidth: 120, flex: 1, editable: true, sortable: false},
        {field: "vendor_name", headerName: "Vendor Name", minWidth: 140, flex: 1, editable: true, sortable: false},
        {field: "version", headerName: "Version", minWidth: 140, flex: 1, editable: true, sortable: false},
        {field: "type", headerName: "Asset Type", minWidth: 140, flex: 1, editable: true, sortable: false},
        {field: "actions", headerName: "Actions", minWidth: 95, flex: 0, editable: false, hideable: false, sortable: false, renderCell: (params: GridRenderCellParams) => {
            const onClick = (event: React.MouseEvent<HTMLElement>): void => {
                event.stopPropagation();

                const objectId = params.row.object_id;
                navigate({pathname: `/asset_groups/${groupName}+${groupId}/${objectId}`})
            }

            return(
                <Button
                    style={{
                        float: "right"}}
                    variant="text"
                    onClick={onClick}
                    disableRipple>
                    View
                </Button>
            );
        }}
    ]

    const paginationModelChanged = (newPaginationModel: {page: number, pageSize: number}): void => {
        setPaginationModel(newPaginationModel);
    };

    const filterModelChanged = (newModel: GridFilterModel): void => {
        filter.current = newModel.quickFilterValues;
        if(filter.current === undefined || filter.current?.length === 0) {
            // If search bar was cleared, return to the unfiltered data
            setSearch(filter.current);
        }
    };

    const rowModesChanged = (newRowModes: GridRowModesModel): void => {
        setRowModesModel(newRowModes);
    }

    const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>): void => {
        // eslint-disable-next-line no-param-reassign
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        // eslint-disable-next-line no-param-reassign
        event.defaultMuiPrevented = true;
    };

    const processRowUpdate = (newRow: AssetGroupDetails): AssetGroupDetails => {
        const updatedRow: AssetGroupDetails = { ...newRow, isNew: false };
        setRows(rows?.map((r) =>
            r.object_id === newRow.object_id ? updatedRow : r
        ))

        const data: AssetGroupDetails = {
            object_id: updatedRow.object_id,
            product_name: updatedRow.product_name,
            description: updatedRow.description,
            vendor_name: updatedRow.vendor_name,
            version: updatedRow.version,
            type: updatedRow.type,
            latest_patch: updatedRow.latest_patch,
            outage: updatedRow.outage,
            isNew: false
        }

        if(newRow.isNew) {
            // Add new row
            setAlert({type: "info", message: "Adding..."})

            postNewCellData(groupId, data, auth).then(() => {
                setAlert({type: "success", message: "New asset added successfully"})
            }).catch(() => {
                setAlert({type: "error", message: "Something went wrong while adding new asset"})
            });
        }
        else {
            // Update existing row
            setAlert({type: "info", message: "Updating..."})

            postEditedCellData(groupId, Number(updatedRow.object_id), data, auth).then(() => {
                setAlert({type: "success", message: "Asset data updated successfully"})
            }).catch(() => {
                setAlert({type: "error", message: "Something went wrong while updating asset data"})
            });
        }

        return updatedRow;
    };

    const visibilityModelChanged = (newVisibilityModel: GridColumnVisibilityModel): void => {
        setVisibilityModel(newVisibilityModel)
    }

    const openAddDialog = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        navigate({pathname: `/asset_groups/${groupName}+${groupId}/add`})
    }

    const columnSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newObject: {[k: string]: any} = {};

        Object.keys(columnSearch).forEach((key) => {
            newObject[key] = columnSearch[key];
        });

        newObject[event.target.id] = event.target.value;

        setColumnSearch(newObject);
    }

    return(
        <Box>
            {/* Temporary until server side pagination is added. Handles error case that normally "crashes" the page */}
            {limitExceeded && (
                <Alert severity="error" sx={{ mb: 2 }}>DataGrid exceeded the limit of 100 items per page</Alert>
            )}
            {AssetGroupDetailsInfo.isError && !limitExceeded && (
                <Alert severity="error" sx={{ mb: 2 }}>Something went wrong while loading backend status</Alert>
            )}
            {!AssetGroupDetailsInfo.isError && !limitExceeded && (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <ShowAlert type={alert.type} message={alert.message}/>
                    <Box style={{height: 670, width: "100%"}}>
                        <CustomDataGrid
                            apiRef={apiRef}
                            allowSearch={false}
                            columnVisibilityModel={visibilityModel}
                            columnVisibilityModelChanged={visibilityModelChanged}
                            allowMultiSelect={false}
                            columnConfig={columnConfig}
                            itemInfo={rows}
                            rowCount={totalRows}
                            paginationModel={paginationModel}
                            paginationModelChanged={paginationModelChanged}
                            filterModelChanged={filterModelChanged}
                            rowHeight={() => "auto"}
                            showAddNewAssetButton
                            // showAddNewAssetButton={IsAdmin()}
                            onRowModesChange={rowModesChanged}
                            rowModes={rowModesModel}
                            rowEditStart={handleRowEditStart}
                            rowEditStop={handleRowEditStop}
                            editMode="row"
                            searchButtonClick={() => {setSearch(filter.current)}}
                            searchEnterKey={() => {setSearch(filter.current)}}
                            initSearch={search}
                            isLoading={AssetGroupDetailsInfo.isLoading}
                            useFilterHeader
                            columnFilter={columnSearch}
                            processRowUpdate={processRowUpdate}
                            onAddNewAsset={openAddDialog}
                            cellKeyDown={(params, event) => {
                                if(params.cellMode === GridRowModes.Edit) {
                                    if(event.code === "Enter") {
                                        event.preventDefault();

                                        // Get visible and editable columns
                                        const columnFields = gridVisibleColumnFieldsSelector(apiRef).filter((field) =>
                                            apiRef.current.isCellEditable(apiRef.current.getCellParams(params.id, field)),
                                        );

                                        // Get index of current column
                                        const index = columnFields.findIndex((field) => field === params.field);

                                        const nextToFocus = columnFields[index + 1];

                                        if(index === columnFields.length - 1) {
                                            // Enter pressed on last column
                                            setRowModesModel({...rowModesModel, [params.id]: {mode: GridRowModes.View}});
                                        }
                                        else {
                                            apiRef.current.setCellFocus(params.id, nextToFocus);
                                        }
                                    }
                                }
                            }}
                            onPageChanged={(event, page) =>
                                setPaginationModel({
                                    page: page - 1,
                                    pageSize: paginationModel.pageSize
                                })
                            }
                            onPageSizeChanged={(event: SelectChangeEvent<number>) => {
                                setPaginationModel({
                                    page: paginationModel.page,
                                    pageSize: event.target.value as number
                                })
                            }}
                            sortModelChanged={(model) => {
                                setSortModel(model);
                            }}
                            onColumnSearchChanged={columnSearchOnChange}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
}

function ShowTab({currentTab, groupId, groupName}: ShowTabProps): JSX.Element {
    const {accessToken, config} = useAuth();
    switch (currentTab) {
        case TabType.Assets:
            return <AssetGroupDetailsGrid
                searchCacheKey={`asset_group_${groupId}_details`}
                groupId={groupId}
                groupName={groupName}
                auth={{accessToken, config}}
            />
        case TabType.Info:
        case TabType.Members:
            return <Typography>Not yet implemented</Typography>
        case TabType.NA:
        default:
            return <Alert severity="error" sx={{ mb: 2 }}>Something went wrong opening this tab</Alert>
    }
}

function TabButton({name, thisTab, currentTab, onButtonClick}: TabButtonProps): JSX.Element {
    return(
        <Button
            disableRipple
            style={{
                color: "#ff7321",
                backgroundColor: "#0f172b",
                borderRadius: 0,
                marginTop: 0,
                marginLeft: 10,
                marginRight: 10,
                marginBottom: -15,
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: currentTab === thisTab ? 6 : 10,
                minWidth: 0,
                fontSize: 12,
                fontWeight: currentTab === thisTab ? "500" : "400",
                textTransform: "none",
                borderBottom: currentTab === thisTab ? "5px solid" : "0px",
            }}
            onClick={onButtonClick}>
            <span style={{color: "#ffffff"}}>
                <Box style={{display: "flex"}}>
                    <Box style={{ clear: "both" }}>
                        {name}
                    </Box>
                </Box>
            </span>
        </Button>
    );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function GroupDetailTabs({currentTab, setTab}: GroupDetailTabProps): JSX.Element {
    return (
        <Box className="tabBar">
            <TabButton name="Registered Assets" thisTab={0} currentTab={currentTab} onButtonClick={() => { setTab(0) }}/>
            <TabButton name="Info" thisTab={1} currentTab={currentTab} onButtonClick={() => { setTab(1) }}/>
            <TabButton name="Members" thisTab={2} currentTab={currentTab} onButtonClick={() => { setTab(2) }}/>
        </Box>
    );
}

export function AssetGroupDetailsView(): JSX.Element {
    const {pathname} = window.location;
    const parts = pathname.split("/");
    const params = parts[2].split("+");

    const groupName = decodeURIComponent(params[0]);
    const groupId = Number(params[1]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [tab, setTab] = useState(0);

    return (
        <Box>
            <Banner firstLine="Registered Asset Groups" secondLine={`View and edit group ${groupName}`} gridBanner/>
            <Container maxWidth="xl">
                {/* <GroupDetailTabs currentTab={tab} setTab={setTab}/> */}
                <ShowTab currentTab={0} groupId={groupId} groupName={groupName}/>
            </Container>
        </Box>
    )
}
